<template>
  <div class="text-a-neutral font-sans tracking-wide">
    <DashboardNav class="hidden md:flex"/>
    <DashboardNavMobile class="flex md:hidden"/>
    <DashboardSideMenuMobile class="flex md:hidden"/>
    <slot :class="['min-h-full p-8 md:p-12 xl:p-24']"></slot>
    <Footer />
  </div>
</template>

<script setup lang="ts">
</script>

<style></style>
